import axios from "../../API/axios";

export const getAllCategories = async(cb:any) => {
    try{
        const response = await axios.get('/categories/1')
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
    }catch(err){
        cb(null, err)
    }
}

export const getAllPhotos = async(cb:any) => {
    try{
        const response = await axios.get('/gallery/photos/1/all')
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
    }catch(err){
        console.log(err)
        cb(null, err)
    }
}

export const getAllVideos = async(cb:any) => {
    try{
        const response = await axios.get('/gallery/video/1/all');
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
    }catch(err){
        cb(null, err)
    }
}

export const getCategoryPhotos = async(page:number, title:string, cb:any) => {
    try{
        const response = await axios.get(`gallery/photos/${page}/${title}`)
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
    }catch(err){
        cb(null, err)
    }
}