import Banner from '../components/Banner';
import Homephoto from '../components/Homephoto';
import Homeblog from '../components/Homeblog';
import Homefeaturedvideos from '../components/Homefeaturedvideos';
import {Helmet} from 'react-helmet'


const Home = () => {
    return (
        <div>
            <Helmet>
                <title>PhotoExplorer</title>
            </Helmet>
            <Banner />
            <Homephoto />
            <Homeblog />
            <Homefeaturedvideos />
        </div>
    )
}

export default Home
