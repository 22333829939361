import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FooterLogo from '../../assets/images/logo.png';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import Sidenav from './Sidenav';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const Header = () => {

    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer)  
    };

    return (
        <>
            <div className="nav__wrapper">
                <header className="frontend_nav">
                    <Container>
                        <AppBar position="static" className="primary__header">
                            <Toolbar>
                                <Typography variant="h6" >
                                    <NavLink to="/">
                                        <img src={FooterLogo} alt="" className="header__logo" />
                                    </NavLink>
                                </Typography>
                                <ul className="navigation__links">
                                    <li>
                                        <NavLink to="/" exact>
                                            Home
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/blog">
                                            Blog
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/gallery">
                                            gallery
                                    </NavLink>
                                    </li>
                                    {/* <li>
                                    <NavLink to="/learn">
                                        learn
                                    </NavLink>
                                </li> */}
                                    <li>
                                        <NavLink to="/contact">
                                            contact us
                                    </NavLink>
                                    </li>
                                </ul>
                                <IconButton edge="start" color="inherit" aria-label="menu" className="side__nav__toggler" onClick={toggleDrawer}>
                                    <MenuIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Container>
                </header>
            </div>
            <Sidenav open={ openDrawer } toggleDrawer={toggleDrawer} />
        </>
    )
}

export default Header
