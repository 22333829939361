import axios from "../../API/axios";

export const getLatestBlog = async(cb:any) => {
    try{
        const response = await axios.get('/home/latest_blogs')
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}

export const getAllBlogs = async(page:number,cb:any) => {
    try{
        const response = await axios.get(`/blog/${page}`)
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}

export const getBlogById = async(id:any, cb:any) => {
    try{
        const response = await axios.get(`/blog/detail/${id}`)
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}