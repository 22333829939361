import Img500 from "../assets/images/500.png";
import { Helmet } from 'react-helmet';

const Pagefivehundred = () => {
    return (
        <div className="error__page__wrapper">
            <Helmet>
                <title>PhotoExplorer - 500 - Internal Server Error</title>
            </Helmet>
            <div>
                <img src={Img500} className="img__width" />
                <p>The page you are looking for cannot be found</p>
                <a href="#">Read more</a>
            </div>
        </div>
    )
}

export default Pagefivehundred
