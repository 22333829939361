
// import { Container, Grid } from '@material-ui/core';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

const Mansory = (props:any) => {
    return (
        <div className="lifestyle__contents">
            <SimpleReactLightbox>
                <SRLWrapper>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                    >
                        <Masonry>
                            {props.children}
                        </Masonry>
                    </ResponsiveMasonry>
                </SRLWrapper>
            </SimpleReactLightbox>
        </div>
    )
}

export default Mansory
