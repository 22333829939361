// import featuredvideoimg from '../assets/images/home-featured-video.png';
// import Dummyvid from '../assets/videos/mov-bbb.mp4';
import { Container } from '@material-ui/core'; 
import { useEffect, useState } from 'react';
import { getFeaturedVideos } from '../core/services/home.service';


const Homefeaturedvideos = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [video, setVideo] = useState<any>({});

    
    const getFeatureVideo = async() => {
        setLoading(true);
        getFeaturedVideos((res: any, err: any) => {
            setLoading(false);
            if (res) {
                setVideo(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    useEffect(() => {
        getFeatureVideo()
    }, [])

    // console.log("video", video)
    return (
        <div className="page__section featured__video__section px__0">
             <div className="section__header">
                <h1>featured videos</h1>
            </div>
            <Container>
           
                <div className="video__wrappers">
                {loading ? <div className="loader"></div> :
                    <video controls>
                      <source src={video?.featuredVideo}  type="video/mp4" />
                    </video> 
                }

                </div>
            </Container>
        </div>
    )
}

export default Homefeaturedvideos