import { Container, Grid } from '@material-ui/core';
import Mustang from '../assets/images/mustang.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getLatestPic } from '../core/services/home.service';

const Homephoto = () => {

    const [featuredImage, setFeaturedImage] = useState([]);

    const getFeaturImage = async() => {
        getLatestPic ((res: any, err: any) => {
            if (res) {
                setFeaturedImage(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(featuredImage);

    useEffect(() => {
        getFeaturImage()
    }, [])

    return (
        <div className="page__section photo__section px__0">
            <Container>
                <div className="section__header">
                    <h1>Photos</h1>
                </div>
                <div className="feature__photos">
                    <Grid container spacing={5}>
                        {featuredImage && featuredImage.map( (feature:any,index) => 
                            <Grid item md={4} sm={6} xs={12} key={index}>
                                <Link className="photo__link__col" to="#">
                                    <div className="photo__col">
                                        <img className="img__responsive w__100" src={feature?.url} />
                                    </div>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default Homephoto