import {Container, Grid} from '@material-ui/core';
import Lifestyle from '../assets/images/fwdsomesamplephotosforgallery/yourshot-1432246-9537643.jpg';
import { Link, useParams } from 'react-router-dom';
import Mansory from '../components/layouts/mansory';
import { getAllCategories, getAllPhotos } from '../core/services/gallery.service';
import { useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';

const Galleryphoto = () => {

    const [loading, setLoading] = useState<boolean>(false);

    const [photos, setPhotos] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);

    const getCategories = async() => {
        setLoading(true);
        getAllCategories((res: any, err: any) => {
            setLoading(false);
            if (res) {
              setCategories(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(categories);

    const getPhotos = async() => {
        getAllPhotos((res: any, err: any) => {
            if (res) {
              setPhotos(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(photos.photos);

    useEffect(() => {
      getPhotos()
      getCategories()
    }, [])

    return(
        <div>
            <Container>
                <div className="catagory__row">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h4 className="title text-center">Photos</h4>
                        </Grid>
                        {!loading ?
                            (categories && categories.doc && categories.doc.slice( 0, 4 ).map((category:any, index:number) =>
                                <Grid item md={3} sm={6} xs={12} key={index}>
                                    <div>
                                        <Link to={`/gallery/${category.title}`}>
                                            <div className="catagory__block">
                                                <a href="#">
                                                    <img src={category?.thumbnailUrl} alt="Category" className="img__responsive" />
                                                </a>
                                                <div className="text-center">
                                                    <Link to={`/gallery/${category.title}`} className="catagory__title">{category.title}</Link>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Grid>
                            )):
                            <>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Skeleton variant="rect" height={200} />
                                    <Skeleton animation="wave" height={25} width={100} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Skeleton variant="rect" height={200} />
                                    <Skeleton animation="wave" height={25} width={100} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Skeleton variant="rect" height={200} />
                                    <Skeleton animation="wave" height={25} width={100} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Skeleton variant="rect" height={200} />
                                    <Skeleton animation="wave" height={25} width={100} />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <h4 className="title text-center">FEATURED IMAGES</h4>
                        </Grid>
                        <Grid item xs={12}>
                            <Mansory>
                                {photos.photos && photos.photos.map( (photo:any, index:number ) =>
                                    <div className="mansory__col" key={index}>
                                        <img className="img__responsive w__100" src={ photo?.url } alt="Featured Images"/>
                                    </div>
                                )}
                            </Mansory>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default Galleryphoto