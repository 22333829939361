import { Drawer } from "@material-ui/core";
import React from "react";
import { NavLink } from 'react-router-dom'

const Sidenav = (props:any) => {

    const list = () => (
        <div
          role="presentation"
          onClick={props.toggleDrawer}
        >
          <ul className="sidebar__navigation">
            <li>
                <NavLink to="/" exact>
                    Home
            </NavLink>
            </li>
            <li>
                <NavLink to="/blog">
                    Blog
            </NavLink>
            </li>
            <li>
                <NavLink to="/gallery">
                    gallery
            </NavLink>
            </li>
            {/* <li>
            <NavLink to="/learn">
                learn
            </NavLink>
           </li> */}
            <li>
                <NavLink to="/contact">
                    contact us
            </NavLink>
            </li>
          </ul>
        </div>
    );

    return (
        <React.Fragment>
          <Drawer anchor="left" open={props.open} onClose={props.toggleDrawer}>
            {list()}
          </Drawer>
        </React.Fragment>
    )
}

export default Sidenav 
