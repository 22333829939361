import { Container, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faMobileAlt ,faEnvelope} from '@fortawesome/free-solid-svg-icons'
import FooterLogo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const Footer = () =>  {
    return (
        <div className="page__footer">
            <Container>
                <div className="footer__top">
                    <Grid container spacing={2}>
                        <Grid item lg={5} md={12} sm={12} xs={12}>
                            <div className="footer__cols col__one">
                                <img src={FooterLogo} alt="" />
                                <p className="footer__text">dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd</p>
                                <div className="social__links">
                                    <a href="#"><i className="fab fa-instagram" aria-hidden="true"></i></a>
                                    <a href="#"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a href="#"><i className="fab fa-youtube" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <div className="footer__cols">
                                <h4 className="col__title">
                                    Ouick Links
                                </h4>
                                <ul>
                                    <li>
                                        <Link to="/" className="footer__text">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/gallery" className="footer__text">Gallery</Link>
                                    </li>
                                    <li>
                                        <Link to="/blog" className="footer__text">blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" className="footer__text">contact us</Link>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <div className="footer__cols">
                                <h4 className="col__title">
                                    contact us
                                </h4>
                                <ul>
                                    <li>
                                        <p className="footer__text"><span className="footer__icons"><FontAwesomeIcon icon={faMapMarkerAlt} /></span>Kathmandu,Nepal</p>
                                    </li>
                                    <li>
                                        <a href="tel:41290231"  className="footer__text"><span className="footer__icons"><FontAwesomeIcon icon={faPhoneAlt} /></span>41290231</a>
                                    </li>
                                    <li>
                                        <a href="tel:980784352" className="footer__text"><span className="footer__icons"><FontAwesomeIcon icon={faMobileAlt} /></span>980784352</a>
                                    </li>
                                    <li>
                                        <a href="mailto:photoexplorer@gmail.com" className="footer__text"><span className="footer__icons"><FontAwesomeIcon icon={faEnvelope} /></span>photoexplorer@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="footer__bottom">
                        <p className="footer__text">
                            Copyright @ 2020.All Rights Reserved By Photoexplorernp
                        </p>
                </div>
            </Container>
        </div>
    )
}

export default Footer
