import {Container, Grid, Button} from '@material-ui/core';
import VidImg from '../assets/images/vid-img.png';
import Videogallery from '../assets/images/gallery-img.png';
import { getAllVideos } from '../core/services/gallery.service';
import { useEffect, useState } from 'react';

const Galleryvideo = () => {

    const [videos, setVideos] = useState<any>([]);

    const getVideos = async() => {
        getAllVideos((res: any, err: any) => {
            if (res) {
              setVideos(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(videos);

    useEffect(() => {
      getVideos()
    }, [])
    return (
        <div>
            <Container>
                <div className="video__row">
                    <Grid container>
                        <Grid lg={5} sm={6} xs={12}>
                            <div className="col__left">
                                <h4 className="title">
                                    Documentary video of patan durbar square
                                </h4>
                                <p className="page__texts">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                                </p>
                                <Button className="play__btn">PLAY Video</Button>
                            </div>
                        </Grid>
                        <Grid lg={7} sm={6} xs={12}>
                            <div className="col__right h__100">
                                <img src={VidImg} alt="Video" className="img__responsive" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="gallery__row">
                    <Grid container spacing={3}>
                        <Grid item lg={12} sm={12} xs={12}>
                            <div className="gallery__title">
                                <h4 className="mb-0">Our Videos</h4>
                            </div>
                        </Grid>
                        {videos && videos.videos && videos.videos.map((video:any, index: number) =>
                            <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
                                <div className="gallery__blocks">
                                    <div className="img__wrapper">
                                        <img src={video.thumbnail} alt="Video Gallery" className="img__responsive" />
                                        <a href={video?.url} className="play__img p-0" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                                <g transform="translate(-332 -866)">
                                                    <rect className="play__path-one" width="50" height="50" rx="5" transform="translate(332 866)"/>
                                                    <path className="play__path-two" d="M11,0,22,20H0Z" transform="translate(367 880) rotate(90)"/>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                    <a href="#" className="block__title">{video?.title}</a>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default Galleryvideo
