import axios from "../../API/axios"

export const getLatestPic = async(cb:any) => {
    try{
        const response = await axios.get('/home/latest_pics')
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}

export const getLatestBlog = async(cb:any) => {
    try{
        const response = await axios.get('/home/latest_blogs')
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}

export const getLatestBannerImages = async(cb:any) => {
    try{
        const response = await axios.get('/home/latest_featured_images')
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}


export const getFeaturedVideos = async(cb:any) => {
    try{
        const response = await axios.get('/gallery/video/getFeaturedVideo')
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}


export const userResponse = async(data:any, cb:any) => {
    try{
        const response = await axios.post('/app-statics', data)
        if (response.status === 200){
            cb(response.data, false)
        }else{
            cb(false, true)
        }
        
    }catch(err){
        cb(null, err)
    }
}