import { Container } from '@material-ui/core';
import { TextField, Button } from '@material-ui/core';
import axios from "../API/axios"
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    
    const [subject, setSubject] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const [subjectError, setSubjectError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [messageError, setMessageError] = useState<string>("");


    const handleSubjectChange = (event:any) => {
        setSubject(event.target.value);
    }

    const handleEmailChange = (event:any) => {
        setEmail(event.target.value)
    }

    const handleMessageChange = (event:any) => {
        setMessage(event.target.value)
    }

    const submitContactForm = async() => {

        let sError = false;
        let eError = false;
        let mError = false;

        if(subject.length === 0 ){
            setSubjectError("Subject is required");
            sError = true;
        }else{
            setSubjectError("");
            sError = false;
        }

        if(email.length === 0 ){
            setEmailError("email is required");
            eError = true;
        }else if ( !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email) ){
            setEmailError("invalid email");
            eError = true;
        }else{
            setEmailError("");
            eError = false;
        }

        if(message.length === 0 ){
            setMessageError("message is required");
            mError = true;
        }else{
            setMessageError("");
            mError = false;
        }

        if( !sError && !eError && !mError){
            const data = {
                subject: subject,
                email: email,
                text: message
            }
            try{
                const response = await axios.post('/contact/send', data)
                if (response.status === 200){
                    console.log("sent successfully")
                    setSubject("")
                    setEmail("")
                    setMessage("")
                }
            }catch(err){
                console.log("not sent")
            }
        }
        
    }


    return (
        <div>
            <Helmet>
                <title>PhotoExplorer - Contact</title>
            </Helmet>
            <div>
                <div className="blogs_banner">
                    <div className="blogs_banner_content">
                        <a href="#" className="btn btn-outline">contact us</a>
                    </div>
                </div>
            </div>
            <div className="contact__form__wrapper">
                <Container>
                    <div className="contact__form">
                        <h4>send us a message</h4>
                        <div className="form__grp">
                            <TextField error={subjectError !== ''} label="Subject" variant="outlined" id="" value={ subject } onChange={ handleSubjectChange } helperText={subjectError ? subjectError: ''} />
                        </div>
                        <div className="form__grp">
                            <TextField error={emailError !== ''} label="Email Address" variant="outlined" id="" value={ email } onChange={ handleEmailChange } helperText={emailError ? emailError: ''} />
                        </div>
                        <div className="form__grp">
                            <TextField error={messageError !== ''} label="Message" variant="outlined" id="" value={ message } onChange={ handleMessageChange } helperText={messageError ? messageError: ''} />
                        </div>
                        <div className="btn__wrpr">
                            <Button variant="contained" className="btn__submit" disableElevation onClick={ submitContactForm }>
                            submit
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Contact
