import Img404 from "../assets/images/404.png";
import { Helmet } from "react-helmet";

const Pagenotfound = () => {
    return (
        <div className="error__page__wrapper">
            <Helmet>
                <title>PhotoExplorer - 404 - Page Not Found</title>
            </Helmet>
            <div>
                <img src={Img404} className="img__width" />
                <p>The page you are looking for cannot be found</p>
                <a href="#">Read more</a>
            </div>
        </div>
    )
}

export default Pagenotfound
