import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getAllBlogs } from "../core/services/blog.service";
import moment from "moment";
import { Skeleton } from '@material-ui/lab';
import { Helmet } from 'react-helmet';

const Blog = () => {

  const [loading, setLoading] = useState<boolean>(false);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalBlogs, setTotalBlogs] = useState(1);

  const [featuredBlogs, setFeaturedBlogs] = useState([]);

  const getFeaturBlog = async () => {
    setLoading(true);
    getAllBlogs(currentPage, (res: any, err: any) => {
      setLoading(false);
      if (res) {
        setCurrentPage(currentPage + 1);
        let newBlogsList = [...featuredBlogs];
        newBlogsList = newBlogsList.concat(res.blog);
        setFeaturedBlogs(newBlogsList);
        setTotalBlogs(res.total);
      }
      if (err) {
        console.log("error", err);
      }
    });
  };

  useEffect(() => {
    getFeaturBlog();
  }, []);

  const getApiDataScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      if (totalBlogs > featuredBlogs.length) {
        getFeaturBlog();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", getApiDataScroll);
    return () => {
      window.removeEventListener("scroll", getApiDataScroll);
    };
  }, [getApiDataScroll]);

  return (
    <div>
      <Helmet>
        <title>PhotoExplorer - Blog</title>
      </Helmet>
      <div className="blog_wrap">
        <div className="blogs_banner">
          <div className="blogs_banner_content">
            <a href="#" className="btn btn-outline">
              BLOGS
            </a>
          </div>
        </div>
        <Container>
          <div className="blog_layout mt-40">
            <Grid container spacing={4}>
              
                {featuredBlogs &&
                  featuredBlogs.map((feature: any, index) => (
                    <Grid item md={4} sm={6} xs={12} key={index}>
                      <div className="blog">
                        <div className="blog_img">
                          <img
                            src={feature?.url[0]}
                            alt="Blog Image"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blog_info">
                          <Link to={`/blog-detail/${feature._id}`}>
                            <div className="blog_info_body d-flex justify-content-center align-items-center flex-column">
                              <p className="text-center">{feature?.title}</p>
                              <span>
                                {moment(feature.createdAt).format("Do MMMM YYYY")}
                              </span>
                              {/* <span>18<sup>th</sup> May 2020</span> */}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </Grid>
                  ))}
                {loading && 
                  <>
                    <Grid item md={4} sm={6} xs={12}>
                        <Skeleton variant="rect" height={300} />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Skeleton variant="rect" height={300} />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Skeleton variant="rect" height={300} />
                    </Grid>
                  </>
                }
            </Grid>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Blog;
