import { Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
// import BannerImageOne from '../assets/images/fwdsomesamplephotosforfashion/bannerone.JPG';
import BannerImageOne from '../assets/images/fwdsomesamplephotosforfashion/bannerone.jpg'
import BannerImageTwo from '../assets/images/fwdsomesamplephotosforfashion/bannertwo.jpg'
import { getLatestBannerImages } from '../core/services/home.service';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
    ]
};

function Banner() {

    const [bannerImage, setBannerImage] = useState([]);

    const getBannerImages = async() => {
        getLatestBannerImages((res: any, err: any) => {
            if (res) {
                setBannerImage(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(bannerImage);

    useEffect(() => {
        getBannerImages()
    }, [])

    return (
        <div className="main__banner">
            <div className="row__wrapper">
                <div className="banner__texts">
                    <div className="banner__text">
                        <h2>Exploring Nature &amp; Cultutre</h2>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et</p>
                        <Button variant="contained" className="btn__submit" disableElevation>
                        Learn More
                        </Button>
                    </div>
                </div>
                <div className="banner__slider">
                    <Slider {...settings}>
                        {bannerImage && bannerImage.map((banner:any,index)=>
                            <div key={index}>
                                <img className="img__responsive" src={banner?.url} />
                            </div>
                        )}
                        {/* <div>
                            <img className="img__responsive" src={BannerImageTwo} />
                        </div>
                        <div>
                            <img className="img__responsive" src={BannerImageOne} />
                        </div>
                        <div>
                            <img className="img__responsive" src={BannerImageTwo} />
                        </div>
                        <div>
                            <img className="img__responsive" src={BannerImageOne} />
                        </div>
                        <div>
                            <img className="img__responsive" src={BannerImageTwo} />
                        </div> */}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Banner
