import axios from "axios";

// const baseURL = "http://localhost:8000";
const baseURL = "https://photot-explorer.herokuapp.com";

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
});

export default axiosInstance;