
import { Container, Grid } from '@material-ui/core';
import Mansory from '../components/layouts/mansory';
import { useState } from 'react';
import { getCategoryPhotos } from '../core/services/gallery.service';
import { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'

const Lifestyle = () => {
    const {id}:any = useParams();

    const [selectCategory,setSelectCategory] = useState("");

    const [categoryPhotos, setcategoryPhotos] = useState([]);

    const getCatPhotos = async() => {
        getCategoryPhotos(1, id, (res: any, err: any) => {
            if (res) {
                setcategoryPhotos(res.photos);
            }
            if (err) {
                console.log("error", err);
            } 
        })
    }
    
    console.log(categoryPhotos);
    useEffect(() => {
        getCatPhotos();
    }, [])

    return (
        <div className="lifestyle__contents bg__coloraccent-1">
            <Helmet>
                <title>PhotoExplorer - LIfestyle</title>
            </Helmet>
            <div className="blogs_banner">
                <div className="blogs_banner_content">
                    <a href="#" className="btn btn-outline">{id}</a>
                </div>
            </div>
            <div className="page__fiter">
                <Container>
                    <div className="page__fiter__inner">
                        <div>
                            <ul>
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li>
                                    {id}
                                </li>
                            </ul>
                        </div>
                        {/* <div>
                            <select value={selectCategory} onChange={(e) => setSelectCategory(e.target.value)} className="category__select">
                                <option value="allCategoreis">All Categories</option>
                                <option value="CatOne">Category 1</option>
                                <option value="CatTwo">Category 2</option>
                            </select>
                        </div> */}
                    </div>
                </Container>
            </div>
            <Container>
                <Mansory>
                    {categoryPhotos && categoryPhotos.map( (photo:any, index:number ) =>
                        <div className="mansory__col" key={index}>
                            <img className="img__responsive" src={ photo?.url } alt=""/>
                        </div>
                    )}
                </Mansory>
            </Container>
        </div>
    )
}

export default Lifestyle
