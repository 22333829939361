import Blogdetailbg from "../assets/images/blog-detail-bg.png";
import Blogdetails from "../assets/images/blog-details.png";
// import Relatedblog from "../assets/images/related-blogs.png";
import { Container, Grid } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getLatestBlog, getBlogById } from '../core/services/blog.service';
import moment from 'moment';
import { Helmet } from 'react-helmet';


const Learn = () => {

    const {id} = useParams();
    console.log(id);  

    const [featuredBlogs, setFeaturedBlogs] = useState([]);
    const [blogDetail, setBlogDetail] = useState([]);

    const getFeaturBlog = async() => {
        getLatestBlog((res, err) => {
            if (res) {
                setFeaturedBlogs(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(featuredBlogs);

    const getBlogDetails = async() => {
        getBlogById(id,(res, err) => {
            if (res) {
                setBlogDetail(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(blogDetail);

    useEffect(() => {
        getFeaturBlog()
        getBlogDetails()
    }, [id])

    

    return (
        <div className="blog__details__contents">
            <Helmet>
                <title>PhotoExplorer - Blog Details</title>
            </Helmet>
            <div>
                <img className="img__responsive" src={Blogdetailbg} />
                <div>
                    <Container>
                        <div className="blog__details__contents__inner">
                            <span className="content__corners corner__top__left"></span>
                            <span className="content__corners corner__top__right"></span>
                            <span className="content__corners corner__bottom__left"></span>
                            <span className="content__corners corner__bottom__right"></span>
                            <div className="content__header">
                                <h1>{blogDetail.title}</h1>
                                <h2>{moment(blogDetail.ceratedAt).format("MMMM Do, YYYY")}</h2>
                            </div>
                            <p>{blogDetail.paragraph_1}</p>
                            <div className="main__img__wrapper">
                                <img className="img__responsive w__100 main__img" src={blogDetail && blogDetail.url && blogDetail.url[0]} />
                            </div>
                            <div className="blog__caption">
                                <p>{blogDetail.paragraph_2}</p>
                            </div>
                            <p>{blogDetail.paragraph_2}</p>
                        </div>
                    </Container>
                </div>
            </div>
            <div className="page__section related__blogs">
                <Container>
                    <div className="section__header">
                        <h1>Related Blogs</h1>
                    </div>
                    <Grid container spacing={3}>
                        {featuredBlogs && featuredBlogs.slice(0, 3).map( (feature,index) =>
                            <Grid item sm={4} key={index}>
                                <Link to={`/blog-detail/${feature._id}`}>
                                    <div className="related__blogs__col">
                                        <div className="col__overlay">
                                            <p>{feature.title}</p>
                                            <span>{moment(feature.createdAt).format("Do MMMM YYYY")}</span>
                                        </div>
                                        <img className="img__responsive w__100" src={feature?.url[0]} />
                                    </div>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </div>
        </div>
    )
}

export default Learn
