import './App.css';
import '../assets/sass/main.scss';
import Header from "../components/layouts/header";
import Footer from "../components/layouts/Footer";
import { Route, Switch } from 'react-router-dom';
import Home from '../containers/home';
import Blog from '../containers/Blog';
import Contact from '../containers/Contact';
import Blogdetail from '../containers/Blogdetail';
import Lifestyle from './Lifestyle';
import Pagenotfound from './Pagenotfound';
import Pagefivehundred from './Pagefivehundred';
import Gallery from './Gallery';
import { useEffect } from 'react';
import { userResponse } from '../core/services/home.service';
// import { Button } from '@material-ui/core';



function App() {

  const postUserVisit = async () => {
    const data = "";
    userResponse(data, (res:any, err:any) => {
      if (err) {
        console.log("error", err);
      }
    })
  }

  useEffect(() => {
    postUserVisit()
  }, [])
  return (
    <div className="App">
      <div className="page__wrapper">
        <Header />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/blog-detail/:id">
            <Blogdetail />
          </Route>
          <Route path="/gallery/:id">
            <Lifestyle />
          </Route>
          <Route path="/gallery">
            <Gallery />
          </Route>
          <Route path="/500">
            <Pagefivehundred />
          </Route>
          <Route path="/*">
            <Pagenotfound />
          </Route>
        </Switch>
        <Footer />
      </div>
    </div>
  );
}

export default App;
