import { Container } from '@material-ui/core'; 
import SwoyambhuPic from '../assets/images/swoyambhu.png';
import 'swiper/swiper.scss'
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
// import "swiper/css/swiper.css";
// import 'react-id-swiper/lib/styles/css/swiper.css';
import 'swiper/swiper.less'
import { getLatestBlog } from '../core/services/home.service';
import { useEffect, useState } from 'react';

const Slider = ({ settings, children } : any) => {
    const params = {
      pagination: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
    //   autoplay: {
    //     delay: 2500,
    //     disableOnInteraction: false
    //   },
      spaceBetween: 30,
      ...settings
    };
  
    return <Swiper {...params}>{children}</Swiper>;
  };


const Homeblog = () => {

    const [featuredBlogs, setFeaturedBlogs] = useState([]);

    const getFeaturBlog = async() => {
        getLatestBlog((res: any, err: any) => {
            if (res) {
                setFeaturedBlogs(res);
            }
            if (err) {
                console.log("error", err);
            }
        })
    }
    console.log(featuredBlogs);

    useEffect(() => {
        getFeaturBlog()
    }, [])

    const settings = {
        effect: "coverflow",
        centeredSlides: true,
        slidesPerView: 3,
        loop: true,
        coverflowEffect: {
          rotate: 20, // Slide rotate in degrees
          stretch: 0, // Stretch space between slides (in px)
          depth: 600, // Depth offset in px (slides translate in Z axis)
          modifier: 1, // Effect multipler
          slideShadows: true // Enables slides shadows
        },
        breakpoints: {
            "0": {
                "slidesPerView": 1
            },
            "768": {
                "slidesPerView": 3
            }
        }
    };

    return (
        <div>
            <div className="page__section our__blog__wrap px__0">
                <Container>
                    <div className="section__header">
                        <h1>Our Blogs</h1>
                    </div>
                    <div className="blogs__slider">
                        <Slider settings={settings}>
                            {featuredBlogs && featuredBlogs.map( (feature:any,index) => 
                                <div className="blog__slide__wrap" key={index}>
                                    <div>
                                        <img className="img__responsive" src={feature?.url[0]} alt='Our Blogs' />
                                    </div>
                                    <div className="blog_info">
                                        <Link to={`/blog-detail/${feature._id}`}>
                                            <div className="blog_info_body">
                                                <div className="texts__wraps">
                                                    <p className="text-center">Video Shoot for music video</p>
                                                    <span>18<sup>th</sup> May 2020</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </Slider>
                        {/* <Slider settings={settings}>
                            <div>
                                <img className="img__responsive" src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHx8&w=1000&q=80" alt='Our Blogs' />
                                <div className="blog_info">
                                    <Link to="/blog-detail">
                                        <div className="blog_info_body">
                                            <div className="texts__wraps">
                                                <p className="text-center">Video Shoot for music video</p>
                                                <span>18<sup>th</sup> May 2020</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <img className="img__responsive" src={SwoyambhuPic} alt='Our Blogs' />
                                <div className="blog_info">
                                    <Link to="/blog-detail">
                                        <div className="blog_info_body">
                                            <div className="texts__wraps">
                                                <p className="text-center">Video Shoot for music video</p>
                                                <span>18<sup>th</sup> May 2020</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <img className="img__responsive" src="https://images.pexels.com/photos/235986/pexels-photo-235986.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt='Our Blogs' />
                                <div className="blog_info">
                                    <Link to="/blog-detail">
                                        <div className="blog_info_body">
                                            <div className="texts__wraps">
                                                <p className="text-center">Video Shoot for music video</p>
                                                <span>18<sup>th</sup> May 2020</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Slider> */}
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Homeblog
