import { Container, AppBar, Tabs, Tab, Grid } from '@material-ui/core'; 
import { useState } from 'react';
import Galleryphoto from '../components/Galleryphoto';
import Galleryvideo from '../components/Galleryvideo';
import { Helmet } from 'react-helmet';

function TabPanel(props: any) {

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  }

const Gallery = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event:any, newValue:any) => {
      setValue(newValue);
    };

    return(
        <div className="gallery__contents">
            <Helmet>
                <title>PhotoExplorer - Gallery</title>
            </Helmet>
            <AppBar position="static" color="transparent">
              <div className="breadcrumb__navigation"> 
                <Container>
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Photos" id={`simple-tab-0`} aria-controls= {`simple-tabpanel-0`} disableRipple />
                        <Tab label="Videos" id={`simple-tab-1`} aria-controls= {`simple-tabpanel-1`} disableRipple />
                    </Tabs>
                </Container>
              </div>
            </AppBar>
            <Container>
                <TabPanel value={value} index={0}>
                    <Galleryphoto />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Galleryvideo />
                </TabPanel>
            </Container>
        </div>
    )
}

export default Gallery;